<template>
  <div id="banners-list-thumb-view" class="data-list-container">
    <div v-if="isLoading" class="flex w-full h-full p-24">
      <img src="@/assets/images/loading1.gif" alt="" width="50" class="mx-auto">
    </div>
    <div v-show="!isLoading">
      <vs-table ref="table" pagination :max-items="itemsPerPage" search :data="filteredItems">
        <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
          <div class="flex flex-wrap-reverse items-center">
            <router-link v-if="$acl.check('admin')" :to="{name: 'bannerCreate'}" class="p-3 mb-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary">
                <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
                <span class="ml-2 text-base text-primary">{{ $t('add_new') }}</span>
            </router-link>
          </div>
          <div>
            <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
              <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                <span class="mx-2"> {{ $t('status') }}: </span>
                <span class="capitalize mr-2">{{ $t(current) }}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item @click="current='all'">
                  <span>{{ $t('all') }}</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="current='active'">
                  <span>{{ $t('only_active') }}</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="current='passive'">
                  <span>{{ $t('only_passive') }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
            <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
              <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ banners.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : banners.length }} of {{ queriedItems }}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item @click="itemsPerPage=4">
                  <span>4</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage=10">
                  <span>10</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage=15">
                  <span>15</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage=20">
                  <span>20</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
        </div>

        <template slot="thead">
          <vs-th class="w-4">{{ $t('fields.image') }}</vs-th>
          <!-- <vs-th :sort-key="`title_${$i18n.locale}`" class="hidden md:table-cell">Name</vs-th> -->
          <!-- <vs-th class="hidden xl:table-cell max-w-2xs">Description</vs-th> -->
          <vs-th class="hidden xl:table-cell">{{ $t('fields.child') }}</vs-th>
          <vs-th>
            <feather-icon icon="Link2Icon" svgClasses="w-5 h-5 mr-1" />
            {{ $t('fields.link') }}
          </vs-th>
          <vs-th sort-key="isActive">{{ $t('fields.status') }}</vs-th>
          <vs-th sort-key="isActive">{{ $t('fields.type') }}</vs-th>
          <vs-th>{{ $t('fields.actions') }}</vs-th>
        </template>

        <template slot-scope="{data}">
          <tbody>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td class="img-container">
                <div class="image darken-bg">
                  <img v-if="tr.slider_image_en" :src="`${$baseUrl}/banners/slider_image_en/${tr.uuid}.webp`" class="product-img" />
                  <span v-else class="m-4">No image!</span>
                </div>
              </vs-td>
              <!-- <vs-td class="hidden md:table-cell">
                <p class="product-name font-medium truncate">{{ tr[`title_${$i18n.locale}`] }}</p>
              </vs-td>
              <vs-td class="hidden xl:table-cell max-w-2xs">
                <p class="truncate">{{ tr[`description_${$i18n.locale}`] }}</p>
              </vs-td> -->
              <vs-td class="hidden xl:table-cell">
                <p class="product-name capitalize font-medium">{{ tr.child_type }}</p>
              </vs-td>
              <vs-td>
                <p v-if="tr.child_type !== 'url'" class="">/{{ tr.child_type }}/{{ tr.childId }}</p>
                <a v-else :href="tr.url"><feather-icon icon="LinkIcon" svgClasses="w-5 h-5 mr-1" /></a>
              </vs-td>
              <vs-td>
                <vs-chip :color="tr.isActive ? 'primary' : 'danger'">
                  <feather-icon v-if="tr.isActive" icon="CheckIcon" svgClasses="w-5 h-5" />
                  <feather-icon v-else icon="XIcon" svgClasses="w-5 h-5" />
                </vs-chip>
              </vs-td>
              <vs-td>
                <p class="product-category">
                  <vs-chip :color="tr.type === 'both' ? 'success' : tr.type === 'express' ? 'primary' : 'warning'">
                    {{ $t(tr.type) }}
                  </vs-chip>
                </p>
              </vs-td>
              <vs-td class="whitespace-no-wrap">
                <feather-icon icon="EyeIcon" svgClasses="w-5 h-5 hover:text-success stroke-current" title="Show banner details" @click.stop="showData(tr.uuid)" />
                <feather-icon v-if="$acl.check('admin')" icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" title="Edit banner details" @click.stop="editData(tr.uuid)" />
                <feather-icon v-if="$acl.check('admin')" icon="RepeatIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" title="Change banner status" @click.stop="changeStatus(tr.uuid, tr.isActive)" />
                <!-- <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="deleteData(tr.uuid)" /> -->
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </vs-table>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isLoading: true,
      banners: [],
      itemsPerPage: 10,
      current: 'all'
    }
  },
  computed: {
    currentPage () {
      if (!this.isLoading) return this.$refs.table.currentx
      return 0
    },
    filteredItems () {
      switch (this.current) {
      case ('all'): return this.banners
      case ('active'): return this.banners.filter((item) => item.isActive)
      case ('passive'): return this.banners.filter((item) => !item.isActive)
      }
    },
    queriedItems () { return this.$refs.table ? this.$refs.table.queriedResults.length : this.banners.length }
  },
  methods: {
    async deleteData (id) {
      await this.$http.delete(`/baners/delete/${id}`).then(() => {
        this.fetchData()
        this.$vs.notify({
          title: 'Success',
          text: 'Banner successfully deleted!',
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'primary'
        })
      }).catch(err => {
        this.$vs.notify({
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
      // this.$store.dispatch('dataList/removeItem', id).catch(err => { console.error(err) })
    },
    async changeStatus (id, status) {
      await this.$http.patch(`/baners/edit/${id}`, {
        isActive: !status
      }).then(() => {
        this.fetchData()
        this.$vs.notify({
          title: 'Success',
          text: 'Banner status successfully changed!',
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'primary'
        })
      }).catch(err => {
        this.$vs.notify({
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
      // this.$store.dispatch('dataList/removeItem', id).catch(err => { console.error(err) })
    },
    editData (id) {
      this.$router.push(`/banners/${id}/edit`)
    },
    showData (id) {
      this.$router.push(`/banners/${id}`)
    },
    async fetchData () {
      await this.$http.get('/baners')
        .then((response) => { 
          this.banners = response.data.data
          this.isLoading = false
        }).catch((error)   => {
          this.isLoading = false
          this.$vs.notify({
            title: 'Error',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
    }
  },
  async created () {
    await this.fetchData()
  }
}
</script>

<style lang="scss">
#banners-list-thumb-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }
    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }
      .vs-table--search{
        padding-top: 0;
        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;
          &+i {
            left: 1rem;
          }
          &:focus+i {
            left: 1rem;
          }
        }
      }
    }
    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;
      tr{
        box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
          td{
            padding: 10px;
            &:first-child{
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
              div {
                height: 46px;
                width: 140px;
                overflow: hidden;
                border-radius: .5rem;
                &.darken-bg {
                  box-shadow: inset 0px 0px 400px 110px rgba(0, 0, 0, .1);
                }
                img {
                  height: 100%;
                  width: 100%;
                  object-position: center;
                  object-fit: contain;
                }
              }
            }
            &:last-child{
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
            &.img-container {
              span {
                display: flex;
                justify-content: flex-start;
              }
            }
          }
          td.td-check{
            padding: 20px !important;
          }
      }
    }
    .vs-table--thead{
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text{
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check{
        padding: 0 15px !important;
      }
      tr{
        background: none;
        box-shadow: none;
      }
    }
    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
